.react-tel-input {
    @apply h-11 xl:h-[50px];
}

.react-tel-input .form-control {
    @apply h-full w-full rounded-[16px] border-black pl-[70px] !important;
}

.react-tel-input .flag-dropdown,
.react-tel-input .flag-dropdown.open {
    @apply rounded-l-[16px] border-black border-r-gray border-r-2 bg-transparent !important;
}

.form-error .react-tel-input .form-control,
.form-error .react-tel-input .flag-dropdown {
    @apply border-red
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus,
.react-tel-input .flag-dropdown.open .selected-flag {
    @apply rounded-l-[16px] !important;
}

.react-tel-input .country-list {
    @apply rounded-[16px] !important;
    font-family: 'Inter', sans-serif;
}

.react-tel-input .country-list .country:hover {
    @apply bg-gray !important;
}

.react-tel-input .country-list .country.highlight {
    @apply bg-gray !important;
}


.react-tel-input .country-list .search-box {
    @apply rounded-[16px] !important;
}

.react-tel-input .selected-flag {
    @apply w-[60px] p-0 flex items-center justify-center !important;
}

.react-tel-input .selected-flag .arrow {
    @apply ml-1;
}


.customPhoneInput.phoneError .form-control {
    @apply border-red !important;
}

.customPhoneInput.phoneError .flag-dropdown,
.customPhoneInput.phoneError .flag-dropdown.open {
    @apply border-red !important;
}
.customPhoneInput.customPhoneInput-disabled .form-control,
.customPhoneInput.customPhoneInput-disabled .flag-dropdown {
    @apply bg-gray dark:bg-gray text-gray
}
