::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb {
  background-color: #9F9F9F;
  border-radius: 20px;
}
