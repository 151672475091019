@tailwind base;
@tailwind components;
@tailwind utilities;

@import "assets/styles/vendors/_phone-input.css";
@import "assets/styles/vendors/_scrollbar.css";

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap");

html {
  @apply scroll-smooth;
}

h1 {
  @apply text-[40px] xl:text-[56px] font-bold leading-[52px] xl:leading-[66px];
}

h2 {
  @apply text-2xl xl:text-[38px] font-semibold  leading-[34px] xl:leading-[44px] capitalize;
}

h3 {
  @apply text-xl xl:text-2xl font-semibold leading-[26px] xl:leading-[34px] capitalize;
}

p {
  @apply text-sm xl:text-lg font-normal leading-[18px] xl:leading-[30px];
}

span {
  @apply text-sm xl:text-lg font-normal leading-[18px] xl:leading-[30px];
}

img {
  @apply rounded-[24px] xl:rounded-[40px];
}

input {
  @apply h-11 xl:h-[50px] px-5 border-black border rounded-[16px] bg-white text-black invalid:border-red placeholder-black focus:outline-none;
}

textarea {
  @apply min-h-[124px] px-5 py-3 border-black border rounded-[16px] bg-white text-black invalid:border-red placeholder-darkGray focus:outline-none;
}